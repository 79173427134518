<div>
  <div class="modal-scrollable" style="z-index: 2070; display: block">
    <div
      class="modal fade in"
      id="filter-dialog"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="false"
      style="display: block; margin-top: -181.5px"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content new-filter">
          <div class="modal-header modal-header-red">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
              (click)="closeDialog()"
            >
              ×
            </button>
            <h4 class="modal-title white-font" id="myModalLabel">My Filters</h4>
          </div>
          @if (isEditing) {
            <app-barge-form
              [filter]="selectedFilter"
              (handleClose)="closeEditor()"
            ></app-barge-form>
          } @else {
            <div
              class="modal-body"
              [style.display]="isEditing ? 'none' : 'block'"
            >
              <div
                class="well list-group bv-form"
                role="form"
                id="UserFilters"
                name="UserFilters"
                data-id=""
                data-toggle="validator"
                style="height: 100%"
                [style.overflow]="isEditing ? 'hidden' : 'auto'"
              >
                <li
                  id="-2"
                  class="form-group list-group-item"
                  [style.top]="isEditing ? '0px' : '0'"
                >
                  <div class="row">
                    <div
                      class="col-xs-10 col-sm-10 col-md-10 col-lg-10 wrapWord"
                    >
                      <input
                        maxlength="50"
                        class="form-control FilterName"
                        name="filter[-2].FilterName"
                        type="text"
                        placeholder="Add New Filter"
                        [readOnly]="!isEditing"
                        data-bv-field="filter[-2].FilterName"
                        [class]="isEditing ? 'isEditing' : ''"
                        [style.box-shadow]="!isEditing ? 'none' : ''"
                      />
                    </div>
                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-right">
                      <button
                        class="btn btn-circle trans btnAddFilter"
                        type="button"
                        (click)="openEditor()"
                      >
                        <span class="glyphicon glyphicon-plus"></span>
                      </button>
                    </div>
                  </div>
                </li>
                @for (
                  filter of filters$ | async;
                  let first = $first;
                  track filter.$id
                ) {
                  <li
                    id="-1"
                    class="list-group-item"
                    (click)="handleChangeFilter(filter)"
                  >
                    <div class="form-group row">
                      <div
                        class="col-xs-10 col-sm-10 col-md-10 col-lg-10 wrapWord"
                      >
                        <span
                          class="glyphicon glyphicon-ok"
                          [class]="
                            filter.DEFAULT_FILTER_FLG === 'Y' ? 'selected' : ''
                          "
                        >
                        </span>
                        <input
                          maxlength="50"
                          class="filter-name form-control FilterName"
                          name="filter[-1].FilterName"
                          type="text"
                          readonly="true"
                          value="{{ filter.FILTER_NAME }}"
                          data-bv-field="filter[-1].FilterName"
                        />
                      </div>
                      @if (filter.FILTERS_ID !== -1) {
                        <div
                          class="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-right"
                        >
                          <button
                            class="btn btn-circle trans btnEdit"
                            type="button"
                            appStopPropagation
                            (click)="openEditor(filter)"
                          >
                            <span class="glyphicon glyphicon-pencil"></span>
                          </button>
                          <button
                            class="btn btn-circle trans btnDelete"
                            type="button"
                            appStopPropagation
                            (click)="openConfirm(filter.FILTERS_ID!)"
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </button>
                        </div>
                      }
                    </div>
                  </li>
                }
              </div>
              <div
                role="form"
                id="filterFrm"
                name="filterFrm"
                data-id=""
                data-toggle="validator"
                class="bv-form"
                style="display: block"
                [style.display]="isEditing ? 'block' : 'none'"
              >
                <div class="form-horizontal">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <input
                        id="DefaultFilter"
                        name="DefaultFilter"
                        type="checkbox"
                      />
                      Default Filter
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group" id="FilterCustomer"></div>
                  </div>
                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="vessel"
                      >Vessel:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div id="vessel" class="ms-ctn" style="width: 100%">
                        <div id="ms-trigger-3" class="ms-trigger">
                          <div class="ms-trigger-ico"></div>
                        </div>
                        <div class="ms-helper" style="display: block"></div>
                        <div id="ms-sel-ctn-3" class="ms-sel-ctn">
                          <input
                            id="ms-input-3"
                            type="text"
                            class="ms-empty-text"
                            value="Select a vessel"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="OriginRiver"
                      >Origin River:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div id="OriginRiver" class="ms-ctn" style="width: 100%">
                        <div id="ms-trigger-0" class="ms-trigger">
                          <div class="ms-trigger-ico"></div>
                        </div>
                        <div class="ms-helper" style="display: block"></div>
                        <div id="ms-sel-ctn-0" class="ms-sel-ctn">
                          <input
                            id="ms-input-0"
                            type="text"
                            class="ms-empty-text"
                            value="Select an origin"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row hidden river-marker col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    id="OriginRivDetails"
                  >
                    <div
                      class="container col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    >
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="OrigMpA"
                          >From MP:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="OrigMpA"
                            name="OrigMpA"
                            data-bv-field="OrigMpA"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="OrigMpA"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="callback"
                            data-bv-for="OrigMpA"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Must add a From MP Value</small
                          >
                        </div>
                      </div>
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="OrigMpB"
                          >To MP:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="OrigMpB"
                            name="OrigMpB"
                            data-bv-field="OrigMpB"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="OrigMpB"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="callback"
                            data-bv-for="OrigMpB"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Must add a To MP Value</small
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="container col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    >
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 hidden"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="OrigFac"
                          >Facility:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="OrigFac"
                            name="OrigFac"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="OrigFacCd"
                          >Facility CD:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            maxlength="2"
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="OrigFacCd"
                            name="OrigFacCd"
                            data-bv-field="OrigFacCd"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="OrigFacCd"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="regexp"
                            data-bv-for="OrigFacCd"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Uppercase letter is required (T or T1)</small
                          ><small
                            class="help-block"
                            data-bv-validator="stringLength"
                            data-bv-for="OrigFacCd"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Please enter a value with valid length</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="DestinationRiver"
                      >Destination River:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div
                        id="DestinationRiver"
                        class="ms-ctn"
                        style="width: 100%"
                      >
                        <div id="ms-trigger-1" class="ms-trigger">
                          <div class="ms-trigger-ico"></div>
                        </div>
                        <div class="ms-helper" style="display: block"></div>
                        <div id="ms-sel-ctn-1" class="ms-sel-ctn">
                          <input
                            id="ms-input-1"
                            type="text"
                            class="ms-empty-text"
                            value="Select an destination"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row hidden river-marker col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    id="DestinationRivDetails"
                  >
                    <div
                      class="container col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    >
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="DestMpA"
                          >From MP:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="DestMpA"
                            name="DestMpA"
                            data-bv-field="DestMpA"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="DestMpA"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="callback"
                            data-bv-for="DestMpA"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Must add a From MP Value</small
                          >
                        </div>
                      </div>
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="DestMpB"
                          >To MP:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="DestMpB"
                            name="DestMpB"
                            data-bv-field="DestMpB"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="DestMpB"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="callback"
                            data-bv-for="DestMpB"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Must add a To MP Value</small
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="container col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    >
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 hidden"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="DestFac"
                          >Facility:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="DestFac"
                            name="DestFac"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="DestFacCd"
                          >Facility CD:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            maxlength="2"
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="DestFacCd"
                            name="DestFacCd"
                            data-bv-field="DestFacCd"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="DestFacCd"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="regexp"
                            data-bv-for="DestFacCd"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Uppercase letter is required (T or T1)</small
                          ><small
                            class="help-block"
                            data-bv-validator="stringLength"
                            data-bv-for="DestFacCd"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Please enter a value with valid length</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="CurrentRiver"
                      >Current River:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div id="CurrentRiver" class="ms-ctn" style="width: 100%">
                        <div id="ms-trigger-2" class="ms-trigger">
                          <div class="ms-trigger-ico"></div>
                        </div>
                        <div class="ms-helper" style="display: block"></div>
                        <div id="ms-sel-ctn-2" class="ms-sel-ctn">
                          <input
                            id="ms-input-2"
                            type="text"
                            class="ms-empty-text"
                            value="Select a current location"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row hidden river-marker col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    id="CurrentRivDetails"
                  >
                    <div
                      class="container col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    >
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="CurrMpA"
                          >From MP:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="CurrMpA"
                            name="CurrMpA"
                            data-bv-field="CurrMpA"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="CurrMpA"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="callback"
                            data-bv-for="CurrMpA"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Must add a From MP Value</small
                          >
                        </div>
                      </div>
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="CurrMpB"
                          >To MP:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="CurrMpB"
                            name="CurrMpB"
                            data-bv-field="CurrMpB"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="CurrMpB"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="callback"
                            data-bv-for="CurrMpB"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Must add a To MP Value</small
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="container col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    >
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 hidden"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="CurrFac"
                          >Facility:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="CurrFac"
                            name="CurrFac"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                      >
                        <label
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                          for="CurrFacCd"
                          >Facility CD:</label
                        >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <input
                            maxlength="2"
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            type="text"
                            id="CurrFacCd"
                            name="CurrFacCd"
                            data-bv-field="CurrFacCd"
                          /><i
                            class="form-control-feedback"
                            data-bv-icon-for="CurrFacCd"
                            style="display: none"
                          ></i>
                          <small
                            class="help-block"
                            data-bv-validator="regexp"
                            data-bv-for="CurrFacCd"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Uppercase letter is required (T or T1)</small
                          ><small
                            class="help-block"
                            data-bv-validator="stringLength"
                            data-bv-for="CurrFacCd"
                            data-bv-result="NOT_VALIDATED"
                            style="display: none"
                            >Please enter a value with valid length</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group has-feedback">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="BargeName"
                      >Specific Barge:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <input
                        id="BargeName"
                        class="form-control"
                        name="BargeName"
                        type="text"
                        data-bv-field="BargeName"
                      /><i
                        class="form-control-feedback"
                        data-bv-icon-for="BargeName"
                        style="display: none"
                      ></i>
                      <small
                        class="help-block"
                        data-bv-validator="regexp"
                        data-bv-for="BargeName"
                        data-bv-result="NOT_VALIDATED"
                        style="display: none"
                        >Invalid barge number</small
                      ><small
                        class="help-block"
                        data-bv-validator="stringLength"
                        data-bv-for="BargeName"
                        data-bv-result="NOT_VALIDATED"
                        style="display: none"
                        >Barge name must be less than 12 characters</small
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="activity"
                      >Activity:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div
                        id="BargeActivityGroup"
                        class="btn-group"
                        data-toggle="buttons"
                      >
                        <label class="btn btn-default active">
                          <input
                            type="radio"
                            name="activity"
                            value=""
                            checked=""
                          />All
                        </label>
                        <label class="btn btn-default">
                          <input
                            type="radio"
                            name="activity"
                            value="MOVING"
                          />Moving
                        </label>
                        <label class="btn btn-default">
                          <input
                            type="radio"
                            name="activity"
                            value="STANDBY/FLEETING"
                          />Stand-By / Fleeting
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="ldStat"
                      >Load Status:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div
                        id="LoadStatGroup"
                        class="btn-group"
                        data-toggle="buttons"
                      >
                        <label class="btn btn-default active">
                          <input
                            type="radio"
                            name="ldStat"
                            value=""
                            checked=""
                          />All
                        </label>
                        <label class="btn btn-default">
                          <input type="radio" name="ldStat" value="L" />Loaded
                        </label>
                        <label class="btn btn-default">
                          <input type="radio" name="ldStat" value="E" />Empty
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="bgType"
                      >Barge Type:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div
                        id="BargeTypeGroup"
                        class="btn-group"
                        data-toggle="buttons"
                      >
                        <label class="btn btn-default active">
                          <input
                            type="radio"
                            name="bgType"
                            value=""
                            checked=""
                          />All
                        </label>
                        <label class="btn btn-default">
                          <input
                            type="radio"
                            name="bgType"
                            value="Open Hopper"
                          />Open Hopper
                        </label>
                        <label class="btn btn-default">
                          <input
                            type="radio"
                            name="bgType"
                            value="Covered Hopper"
                          />Covered Hopper
                        </label>
                        <label class="btn btn-default">
                          <input
                            type="radio"
                            name="bgType"
                            value="Deck Barge"
                          />Deck Barge
                        </label>
                        <label class="btn btn-default">
                          <input
                            type="radio"
                            name="bgType"
                            value="Double Tank Barge"
                          />Double Tank Barge
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group has-feedback">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="Commodity"
                      >Commodity:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <input
                        id="Commodity"
                        name="Commodity"
                        type="text"
                        class="form-control"
                        data-bv-field="Commodity"
                      /><i
                        class="form-control-feedback"
                        data-bv-icon-for="Commodity"
                        style="display: none"
                      ></i>
                      <small
                        class="help-block"
                        data-bv-validator="stringLength"
                        data-bv-for="Commodity"
                        data-bv-result="NOT_VALIDATED"
                        style="display: none"
                        >Commodity name must be less than 150 characters</small
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="CustJobId"
                      >Customer Order Number:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <input
                        id="CustJobId"
                        name="CustJobId"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      for="CustVesselName"
                      >Ship Name:</label
                    >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <input
                        id="CustVesselName"
                        name="CustVesselName"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer modal-header-red"
              [style.display]="isEditing ? 'none' : 'block'"
            >
              <div class="form-group">
                <button
                  type="button"
                  class="btn cancel-button pull-right"
                  data-dismiss="modal"
                  [disabled]="isUpdating"
                  (click)="closeDialog()"
                >
                  Close
                </button>
                @if (!isNotificationsPage) {
                  <button
                    id="btnRefreshDashboard"
                    type="button"
                    class="btn border-button border-button-fill pull-right"
                    data-dismiss="modal"
                    (click)="handleRefresh()"
                    [disabled]="isUpdating"
                  >
                    Refresh
                  </button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal-backdrop fade in"
    style="z-index: 1040; display: none"
  ></div>
</div>
