import { Component, inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [],
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  confirmRef = inject(DialogRef);

  confirmDialog() {
    this.confirmRef.close(true);
  }

  closeDialog() {
    this.confirmRef.close(false);
  }
}
