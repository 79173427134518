import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { catchError, lastValueFrom, Observable, switchMap, tap } from 'rxjs';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { UserService } from '@/app/core/services/user.service';
import { Filter } from '@/app/core/models/user.model';
import { StaticDataService } from '@/app/core/services/staticData.service';
import { Boat, River } from '@/app/core/models/dashboard.model';
import {
  verifyOtherFieldHasValue,
  firstUpperAndUpperOrNumber,
  onlyLetterOrNumber,
  parseStringIfEmpty,
} from '@/app/core/utils/Validations.util';
import { AuthService } from '@/app/core/services/auth.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FailedModalComponent } from '../failed-modal/failed-modal.component';
import { Nullable } from '@/types';

@Component({
  selector: 'app-barge-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    HttpClientModule,
    AsyncPipe,
    NgIf,
    FormsModule,
    NgSelectModule,
    CommonModule,
  ],
  templateUrl: './barge-form.component.html',
})
export class BargeFormComponent implements OnInit {
  @Output() handleClose = new EventEmitter();
  @Input() filter!: Filter | null;
  private staticDataService = inject(StaticDataService);
  private userService = inject(UserService);
  private dialogRef = inject(DialogRef);
  private authService = inject(AuthService);
  private failedDialog = inject(Dialog);
  boatsOptions$!: Observable<Boat[]>;
  riversOptions$!: Observable<River[]>;
  public customers$ = this.staticDataService.getCustomers();
  isLoading = false;
  public user$ = this.userService.getCurrentUser();

  bargeForm = new FormGroup(
    {
      FILTERS_ID: new FormControl(this.filter?.FILTERS_ID ?? null),
      FILTER_NAME: new FormControl('', [
        Validators.maxLength(50),
        Validators.required,
      ]),
      DEFAULT_FILTER_FLG: new FormControl(''),
      CUSTODIAN: new FormControl(null),
      CUSTOMER_NUMBER: new FormControl(null),
      ORIGIN_RIVER: new FormControl(null),
      ORIGIN_MP_A: new FormControl(null),
      ORIGIN_MP_B: new FormControl(null),
      ORIGIN_FAC_NAME: new FormControl(null),
      ORIGIN_FACILITY_CD: new FormControl(
        null,
        Validators.pattern(firstUpperAndUpperOrNumber),
      ),
      DEST_RIVER: new FormControl(null),
      DEST_MP_A: new FormControl(null),
      DEST_MP_B: new FormControl(null),
      DEST_FAC_NAME: new FormControl(null),
      DEST_FACILITY_CD: new FormControl(
        null,
        Validators.pattern(firstUpperAndUpperOrNumber),
      ),
      CURRENT_RIVER: new FormControl(null),
      CURRENT_MP_A: new FormControl(null),
      CURRENT_MP_B: new FormControl(null),
      CURRENT_FACILITY_CD: new FormControl(
        null,
        Validators.pattern(firstUpperAndUpperOrNumber),
      ),
      BARGENAME: new FormControl(null, [
        Validators.pattern(onlyLetterOrNumber),
        Validators.maxLength(12),
      ]),
      BARGEEVT: new FormControl(null),
      LOADEMPTY: new FormControl(null),
      BARGETYPE: new FormControl(null),
      CARGO_TYPE: new FormControl(null, Validators.maxLength(150)),
      CUST_JOB_ID: new FormControl(null),
      VESSEL_NAME: new FormControl(null),

      CONDITIONA: new FormControl(null),
      CONDITIONB: new FormControl(null),
      CREATED_ON: new FormControl(null),
      CREATED_BY: new FormControl(null),
      UPDATED_BY: new FormControl(null),
    },
    {
      validators: [
        (form) =>
          verifyOtherFieldHasValue(
            form,
            'ORIGIN_MP_A',
            'ORIGIN_MP_B',
            'origMpARequired',
          ),
        (form) =>
          verifyOtherFieldHasValue(
            form,
            'ORIGIN_MP_B',
            'ORIGIN_MP_A',
            'origMpBRequired',
          ),
        (form) =>
          verifyOtherFieldHasValue(
            form,
            'DEST_MP_A',
            'DEST_MP_B',
            'destMpARequired',
          ),
        (form) =>
          verifyOtherFieldHasValue(
            form,
            'DEST_MP_B',
            'DEST_MP_A',
            'destMpBRequired',
          ),
        (form) =>
          verifyOtherFieldHasValue(
            form,
            'CURRENT_MP_A',
            'CURRENT_MP_B',
            'CurrMpARequired',
          ),
        (form) =>
          verifyOtherFieldHasValue(
            form,
            'CURRENT_MP_B',
            'CURRENT_MP_A',
            'CurrMpBRequired',
          ),
      ],
    },
  );

  closeDialog() {
    this.dialogRef.close();
  }

  private cleanEmptyStrings() {
    return {
      FILTER_NAME: this.bargeForm.value.FILTER_NAME as string,
      ORIGIN_RIVER: this.bargeForm.value.ORIGIN_RIVER as Nullable<string>,
      ORIGIN_MP_A: this.bargeForm.value.ORIGIN_MP_A as Nullable<string>,
      ORIGIN_MP_B: this.bargeForm.value.ORIGIN_MP_B as Nullable<string>,
      ORIGIN_FACILITY_CD: parseStringIfEmpty(
        this.bargeForm.value.ORIGIN_FACILITY_CD,
      ),
      ORIGIN_FAC_NAME: parseStringIfEmpty(this.bargeForm.value.ORIGIN_FAC_NAME),
      CURRENT_RIVER: this.bargeForm.value.CURRENT_RIVER as Nullable<string>,
      CURRENT_MP_A: this.bargeForm.value.CURRENT_MP_A as Nullable<string>,
      CURRENT_MP_B: this.bargeForm.value.CURRENT_MP_B as Nullable<string>,
      CURRENT_FACILITY_CD: parseStringIfEmpty(
        this.bargeForm.value.CURRENT_FACILITY_CD,
      ),
      DEST_RIVER: this.bargeForm.value.DEST_RIVER as Nullable<string>,
      DEST_MP_A: this.bargeForm.value.DEST_MP_A as Nullable<string>,
      DEST_MP_B: this.bargeForm.value.DEST_MP_B as Nullable<string>,
      DEST_FACILITY_CD: parseStringIfEmpty(
        this.bargeForm.value.DEST_FACILITY_CD,
      ),
      DEST_FAC_NAME: parseStringIfEmpty(this.bargeForm.value.DEST_FAC_NAME),
      BARGEEVT: parseStringIfEmpty(this.bargeForm.value.BARGEEVT),
      LOADEMPTY: parseStringIfEmpty(this.bargeForm.value.LOADEMPTY),
      CUSTODIAN: parseStringIfEmpty(this.bargeForm.value.CUSTODIAN),
      CARGO_TYPE: parseStringIfEmpty(this.bargeForm.value.CARGO_TYPE),
      BARGETYPE: parseStringIfEmpty(this.bargeForm.value.BARGETYPE),
      BARGENAME: parseStringIfEmpty(this.bargeForm.value.BARGENAME),
      CUSTOMER_NUMBER: this.bargeForm.value.CUSTOMER_NUMBER as Nullable<string>,
      CUST_JOB_ID: parseStringIfEmpty(this.bargeForm.value.CUST_JOB_ID),
      VESSEL_NAME: parseStringIfEmpty(this.bargeForm.value.VESSEL_NAME),
    };
  }

  toValues(username: string) {
    const values = this.bargeForm.value;
    const cleanedValues = this.cleanEmptyStrings();

    return {
      ...values,
      ...cleanedValues,
      $id: this.filter?.$id,
      ...(this.filter?.FILTER_ACTIVE
        ? { FILTER_ACTIVE: this.filter?.FILTER_ACTIVE }
        : {}),
      CREATED_BY: values.CREATED_BY ?? username,
      UPDATED_BY: values.UPDATED_BY ?? username,
      UPDATED_ON: new Date().toISOString(),
      CREATED_ON: values.CREATED_ON ?? new Date().toISOString(),
      DEFAULT_FILTER_FLG: values.DEFAULT_FILTER_FLG ?? 'N',
      FILTERS_ID: values.FILTERS_ID ?? undefined,
      USERNAME: username,
    } as unknown as Filter;
  }
  async onSubmit() {
    this.isLoading = true;
    await lastValueFrom(
      this.authService.getCurrentUsername().pipe(
        switchMap((username) =>
          this.userService.saveFilter(this.toValues(username)),
        ),
        tap(() => this.handleClose.emit()),
        catchError((response) => {
          const failedRef = this.failedDialog.open(FailedModalComponent, {
            data: response.error.Message ?? 'Failed to save filter',
          });
          return lastValueFrom(failedRef.closed);
        }),
      ),
    );
    this.isLoading = false;
  }
  handleOriginRiverChange() {
    this.bargeForm.patchValue({
      ORIGIN_MP_A: null,
      ORIGIN_MP_B: null,
      ORIGIN_FAC_NAME: null,
      ORIGIN_FACILITY_CD: null,
    });
  }
  handleDestRiverChange() {
    this.bargeForm.patchValue({
      DEST_MP_A: null,
      DEST_MP_B: null,
      DEST_FAC_NAME: null,
      DEST_FACILITY_CD: null,
    });
  }
  handleCurrentRiverChange() {
    this.bargeForm.patchValue({
      CURRENT_MP_A: null,
      CURRENT_MP_B: null,
      CURRENT_FACILITY_CD: null,
    });
  }

  formatValues(filter: Filter) {
    return this.bargeForm.patchValue({
      ...filter,
      BARGEEVT: filter?.BARGEEVT ?? null,
      LOADEMPTY: filter?.LOADEMPTY ?? null,
      BARGETYPE: filter?.BARGETYPE ?? null,
    } as object);
  }
  async ngOnInit() {
    this.boatsOptions$ = this.staticDataService.getBoats();
    this.riversOptions$ = this.staticDataService.getRivers();
    if (this.filter) {
      this.formatValues(this.filter);
    }
  }
}
