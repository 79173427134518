<div class="modal-scrollable" style="z-index: 3100">
  <div
    class="modal fade in"
    id="delete-dialog"
    role="dialog"
    aria-labelledby="myModalLabel"
    tabindex="-1"
    aria-hidden="false"
    style="display: block; margin-top: -167px"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content delete-filter">
        <div class="modal-header modal-header-red">
          <h4 class="modal-title white-font" id="myModalLabel">Delete</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 wrapWord">
              <span class="deletePrompt" style="color: black"
                >Are you sure you want to delete this filter?</span
              >
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
          </div>
        </div>
        <div class="modal-footer modal-header-red">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <button
                id="7760"
                class="btn btnYes border-button border-button-fill"
                type="button"
                (click)="confirmDialog()"
              >
                Yes
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <button
                class="btn btnNo cancel-button"
                type="button"
                data-dismiss="modal"
                (click)="closeDialog()"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
