import { Component, inject, OnInit } from '@angular/core';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { HttpClientModule } from '@angular/common/http';
import { AsyncPipe } from '@angular/common';
import { catchError, lastValueFrom, Observable, of } from 'rxjs';
import { SharedModule } from '@/app/shared/shared.module';
import { UserService } from '@/app/core/services/user.service';
import { Filter, FiltersFlag } from '@/app/core/models/user.model';
import { SidenavComponent } from '@/app/core/layout/sidenav/sidenav.component';

import { BargeFormComponent } from './barge-form/barge-form.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-barge-modal',
  standalone: true,
  imports: [
    HttpClientModule,
    AsyncPipe,
    BargeFormComponent,
    SidenavComponent,
    SharedModule,
  ],
  templateUrl: './barge-modal.component.html',
})
export class BargeModalComponent implements OnInit {
  isNotificationsPage: boolean = inject(DIALOG_DATA);

  private dialogRef = inject(DialogRef);
  private userService = inject(UserService);
  private confirmDialog = inject(Dialog);
  isEditing = false;
  filters$!: Observable<Filter[]>;
  isUpdating = false;
  selectedFilter: Filter | null = null;

  // for deleting a filter
  async openConfirm(filterId: number) {
    this.isUpdating = true;
    const confirmRef = this.confirmDialog.open<boolean>(ConfirmModalComponent);
    const isConfirmed = await lastValueFrom(confirmRef.closed);
    if (isConfirmed) {
      await lastValueFrom(
        this.userService
          .deleteFilter(filterId)
          .pipe(catchError(() => of(null))),
      );
    }
    this.isUpdating = false;
  }
  closeDialog() {
    this.dialogRef.close();
  }
  // when selecting a filter
  async handleChangeFilter(filter: Filter) {
    if (this.isNotificationsPage) {
      this.dialogRef.close(filter);
      return;
    }

    const newFilter = { ...filter, DEFAULT_FILTER_FLG: FiltersFlag.YES };
    this.isUpdating = true;
    await lastValueFrom(
      this.userService.updateFilter(newFilter).pipe(catchError(() => of(null))),
    );
    this.isUpdating = false;
  }
  // after creating or editing a filter
  closeEditor() {
    this.isEditing = false;
    this.selectedFilter = null;
  }
  openEditor(filter?: Filter) {
    if (filter) {
      this.selectedFilter = filter;
    }
    this.isEditing = true;
  }
  handleRefresh() {
    this.userService.refreshCurrentUser();
    this.dialogRef.close();
  }
  ngOnInit() {
    this.filters$ = this.userService.getFilters();
  }
}
