<form
  style="display: block; height: 100%"
  [formGroup]="bargeForm"
  (ngSubmit)="onSubmit()"
>
  <div class="modal-body" style="overflow: auto">
    <div class="well form-group list-group-item">
      <div class="row" style="margin: 0">
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 wrapWord">
          <input
            formControlName="FILTER_NAME"
            id="FilterName"
            maxlength="50"
            class="form-control FilterName editing"
            name="filter[-2].FilterName"
            type="text"
            placeholder="Add New Filter"
            data-bv-field="filter[-2].FilterName"
            style="box-shadow: none"
          />
          @if (
            bargeForm.get("FILTER_NAME")?.dirty &&
            bargeForm.get("FILTER_NAME")?.hasError("required")
          ) {
            <small
              class="help-block error-color"
              data-bv-validator="notEmpty"
              data-bv-for="FilterName"
              data-bv-result="NOT_VALIDATED"
            >
              Filter name is required
            </small>
          } @else if (
            bargeForm.get("FILTER_NAME")?.dirty &&
            bargeForm.get("FILTER_NAME")?.hasError("maxLength")
          ) {
            <small
              class="help-block error-color"
              data-bv-validator="stringLength"
              data-bv-for="FilterName"
              data-bv-result="NOT_VALIDATED"
            >
              Filter name must be less than 50 characters
            </small>
          } @else if (
            bargeForm.get("FILTER_NAME")?.dirty &&
            bargeForm.get("FILTER_NAME")?.hasError("invalid")
          ) {
            <small
              class="help-block error-color"
              data-bv-validator="stringLength"
              data-bv-for="filter[-2].FilterName"
              data-bv-result="NOT_VALIDATED"
            >
              Please enter a value with valid length
            </small>
          }
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-right">
          <button
            class="btn btn-circle trans btnAddFilter"
            type="button"
            style="color: black"
            (click)="handleClose.emit()"
          >
            <span class="glyphicon glyphicon-remove"></span>
          </button>
        </div>
      </div>
    </div>
    <div id="filterFrm" class="bv-form" style="display: block; height: 100%">
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="form-group">
              <input
                id="DefaultFilter"
                name="DefaultFilter"
                type="checkbox"
                [ngModel]="bargeForm.get('DEFAULT_FILTER_FLG')?.value === 'Y'"
                (ngModelChange)="
                  bargeForm
                    .get('DEFAULT_FILTER_FLG')
                    ?.setValue($event ? 'Y' : 'B')
                "
                [ngModelOptions]="{ standalone: true }"
              />
              <label
                for="DefaultFilter"
                style="padding-left: 10px; user-select: none"
              >
                Default Filter
              </label>
            </div>
          </div>
        </div>

        @if (user$ | async; as user) {
          @if (user.INTERNAL_USER) {
            <div class="form-group">
              <label
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                for="Customer"
              >
                Customer Name:
              </label>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ng-select
                  [items]="customers$ | async"
                  bindLabel="customer_display"
                  bindValue="customer_number"
                  labelForId="Customer"
                  class="ms-ctn w-100"
                  placeholder="Select a customer"
                  formControlName="CUSTOMER_NUMBER"
                />
              </div>
            </div>
          }
        }

        <div class="form-group">
          <label class="col-xs-12 col-sm-12 col-md-12 col-lg-12" for="vessel">
            Vessel:
          </label>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ng-select
              [items]="boatsOptions$ | async"
              bindLabel="BOATNAME"
              bindValue="BOATNAME"
              labelForId="vessel"
              class="ms-ctn w-100"
              placeholder="Select a vessel"
              formControlName="CUSTODIAN"
            />
          </div>
        </div>

        <ng-container *ngIf="riversOptions$ | async as riversOptions">
          <div class="form-group">
            <label
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              for="OriginRiver"
            >
              Origin River:
            </label>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ng-select
                [items]="riversOptions"
                bindLabel="RIVER_ABRV"
                bindValue="RIVER_ABRV"
                labelForId="OriginRiver"
                class="ms-ctn w-100"
                placeholder="Select an origin"
                formControlName="ORIGIN_RIVER"
                (ngModelChange)="handleOriginRiverChange()"
              />
            </div>
          </div>
          @if (bargeForm.get("ORIGIN_RIVER")?.value) {
            <div
              class="row river-marker col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="OriginRivDetails"
            >
              <div class="container col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="OrigMpA"
                  >
                    From MP:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="ORIGIN_MP_A"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="OrigMpA"
                      name="OrigMpA"
                      data-bv-field="OrigMpA"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="OrigMpA"
                      style="display: none"
                    ></i>
                    @if (bargeForm.hasError("origMpARequired")) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="callback"
                        data-bv-for="OrigMpA"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Must add a From MP Value
                      </small>
                    }
                  </div>
                </div>
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="OrigMpB"
                  >
                    To MP:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="ORIGIN_MP_B"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="OrigMpB"
                      name="OrigMpB"
                      data-bv-field="OrigMpB"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="OrigMpB"
                      style="display: none"
                    ></i>
                    @if (bargeForm.hasError("origMpBRequired")) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="callback"
                        data-bv-for="OrigMpB"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Must add a To MP Value
                      </small>
                    }
                  </div>
                </div>
              </div>
              <div class="container col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 hidden"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="OrigFac"
                  >
                    Facility:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="ORIGIN_FAC_NAME"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="OrigFac"
                      name="OrigFac"
                    />
                  </div>
                </div>
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="OrigFacCd"
                  >
                    Facility CD:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="ORIGIN_FACILITY_CD"
                      maxlength="2"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="OrigFacCd"
                      name="OrigFacCd"
                      data-bv-field="OrigFacCd"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="OrigFacCd"
                      style="display: none"
                    ></i>
                    @if (
                      bargeForm.get("ORIGIN_FACILITY_CD")?.dirty &&
                      bargeForm.get("ORIGIN_FACILITY_CD")?.hasError("pattern")
                    ) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="regexp"
                        data-bv-for="OrigFacCd"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Uppercase letter is required (T or T1)
                      </small>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          <div class="form-group">
            <label
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              for="DestinationRiver"
            >
              Destination River:
            </label>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ng-select
                [items]="riversOptions"
                bindLabel="RIVER_ABRV"
                bindValue="RIVER_ABRV"
                labelForId="DestinationRiver"
                class="ms-ctn w-100"
                placeholder="Select an destination"
                formControlName="DEST_RIVER"
                (ngModelChange)="handleDestRiverChange()"
              />
            </div>
          </div>
          @if (bargeForm.get("DEST_RIVER")?.value) {
            <div
              class="row river-marker col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="DestinationRivDetails"
            >
              <div class="container col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="DestMpA"
                  >
                    From MP:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="DEST_MP_A"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="DestMpA"
                      name="DestMpA"
                      data-bv-field="DestMpA"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="DestMpA"
                      style="display: none"
                    ></i>
                    @if (bargeForm.hasError("destMpARequired")) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="callback"
                        data-bv-for="DestMpA"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Must add a From MP Value
                      </small>
                    }
                  </div>
                </div>
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="DestMpB"
                  >
                    To MP:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="DEST_MP_B"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="DestMpB"
                      name="DestMpB"
                      data-bv-field="DestMpB"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="DestMpB"
                      style="display: none"
                    ></i>
                    @if (bargeForm.hasError("destMpBRequired")) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="callback"
                        data-bv-for="DestMpB"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Must add a To MP Value
                      </small>
                    }
                  </div>
                </div>
              </div>
              <div class="container col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 hidden"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="DestFac"
                  >
                    Facility:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="DEST_FAC_NAME"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="DestFac"
                      name="DestFac"
                    />
                  </div>
                </div>
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="DestFacCd"
                  >
                    Facility CD:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="DEST_FACILITY_CD"
                      maxlength="2"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="DestFacCd"
                      name="DestFacCd"
                      data-bv-field="DestFacCd"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="DestFacCd"
                      style="display: none"
                    ></i>
                    @if (
                      bargeForm.get("DEST_FACILITY_CD")?.dirty &&
                      bargeForm.get("DEST_FACILITY_CD")?.hasError("pattern")
                    ) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="regexp"
                        data-bv-for="DestFacCd"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Uppercase letter is required (T or T1)
                      </small>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          <div class="form-group">
            <label
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              for="CurrentRiver"
            >
              Current River:
            </label>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ng-select
                [items]="riversOptions"
                bindLabel="RIVER_ABRV"
                bindValue="RIVER_ABRV"
                labelForId="CurrentRiver"
                class="ms-ctn w-100"
                placeholder="Select a current locatior"
                formControlName="CURRENT_RIVER"
                (ngModelChange)="handleCurrentRiverChange()"
              />
            </div>
          </div>
          @if (bargeForm.get("CURRENT_RIVER")?.value) {
            <div
              class="row river-marker col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="CurrentRivDetails"
            >
              <div class="container col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="CurrMpA"
                  >
                    From MP:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="CURRENT_MP_A"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="CurrMpA"
                      name="CurrMpA"
                      data-bv-field="CurrMpA"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="CurrMpA"
                      style="display: none"
                    ></i>
                    @if (bargeForm.hasError("CurrMpARequired")) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="callback"
                        data-bv-for="CurrMpA"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Must add a From MP Value
                      </small>
                    }
                  </div>
                </div>
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="CurrMpB"
                  >
                    To MP:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="CURRENT_MP_B"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="CurrMpB"
                      name="CurrMpB"
                      data-bv-field="CurrMpB"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="CurrMpB"
                      style="display: none"
                    ></i>
                    @if (bargeForm.hasError("CurrMpBRequired")) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="callback"
                        data-bv-for="CurrMpB"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Must add a To MP Value
                      </small>
                    }
                  </div>
                </div>
              </div>
              <div class="container col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 has-feedback"
                >
                  <label
                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                    for="CurrFacCd"
                  >
                    Facility CD:
                  </label>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <input
                      formControlName="CURRENT_FACILITY_CD"
                      maxlength="2"
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      type="text"
                      id="CurrFacCd"
                      name="CurrFacCd"
                      data-bv-field="CurrFacCd"
                    />
                    <i
                      class="form-control-feedback"
                      data-bv-icon-for="CurrFacCd"
                      style="display: none"
                    ></i>
                    @if (
                      bargeForm.get("CURRENT_FACILITY_CD")?.dirty &&
                      bargeForm.get("CURRENT_FACILITY_CD")?.hasError("pattern")
                    ) {
                      <small
                        class="help-block error-color"
                        data-bv-validator="regexp"
                        data-bv-for="CurrFacCd"
                        data-bv-result="NOT_VALIDATED"
                      >
                        Uppercase letter is required (T or T1)
                      </small>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </ng-container>

        <div class="form-group has-feedback">
          <label
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            for="BargeName"
          >
            Specific Barge:
          </label>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <input
              formControlName="BARGENAME"
              id="BargeName"
              class="form-control"
              name="BargeName"
              type="text"
              data-bv-field="BargeName"
            />
            <i
              class="form-control-feedback"
              data-bv-icon-for="BargeName"
              style="display: none"
            ></i>
            @if (
              bargeForm.get("BARGENAME")?.dirty &&
              bargeForm.get("BARGENAME")?.hasError("pattern")
            ) {
              <small
                class="help-block error-color"
                data-bv-validator="regexp"
                data-bv-for="BargeName"
                data-bv-result="NOT_VALIDATED"
              >
                Invalid barge number
              </small>
            }
            @if (
              bargeForm.get("BARGENAME")?.dirty &&
              bargeForm.get("BARGENAME")?.hasError("maxLength")
            ) {
              <small
                class="help-block error-color"
                data-bv-validator="stringLength"
                data-bv-for="BargeName"
                data-bv-result="NOT_VALIDATED"
              >
                Barge name must be less than 12 characters
              </small>
            }
          </div>
        </div>

        <div class="form-group">
          <label
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            for="BargeActivityGroup"
          >
            Activity:
          </label>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div
              id="BargeActivityGroup"
              class="btn-group radio-group"
              data-toggle="buttons"
            >
              <input
                formControlName="BARGEEVT"
                name="BARGEEVT"
                id="barge-all"
                type="radio"
                value=""
              />
              <label
                for="barge-all"
                class="btn btn-default"
                [ngClass]="{
                  active: !bargeForm.controls.BARGEEVT.value
                }"
              >
                All
              </label>
              <input
                formControlName="BARGEEVT"
                name="BARGEEVT"
                id="barge-moving"
                type="radio"
                value="MOVING"
              />
              <label
                for="barge-moving"
                class="btn btn-default"
                [ngClass]="{
                  active: bargeForm.controls.BARGEEVT.value === 'MOVING'
                }"
              >
                Moving
              </label>
              <input
                formControlName="BARGEEVT"
                name="BARGEEVT"
                id="barge-stand"
                type="radio"
                value="STANDBY/FLEETING"
              />
              <label
                for="barge-stand"
                class="btn btn-default"
                [ngClass]="{
                  active:
                    bargeForm.controls.BARGEEVT.value === 'STANDBY/FLEETING'
                }"
              >
                Stand-By / Fleeting
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            for="LoadStatGroup"
          >
            Load Status:
          </label>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div
              id="LoadStatGroup"
              class="btn-group radio-group"
              data-toggle="buttons"
            >
              <input
                formControlName="LOADEMPTY"
                name="LOADEMPTY"
                id="ldStat-all"
                type="radio"
                value=""
              />
              <label
                for="ldStat-all"
                class="btn btn-default"
                [ngClass]="{
                  active: !bargeForm.controls.LOADEMPTY.value
                }"
              >
                All
              </label>
              <input
                formControlName="LOADEMPTY"
                name="LOADEMPTY"
                id="ldStat-loaded"
                type="radio"
                value="L"
              />
              <label
                for="ldStat-loaded"
                class="btn btn-default"
                [ngClass]="{
                  active: bargeForm.controls.LOADEMPTY.value === 'L'
                }"
              >
                Loaded
              </label>
              <input
                formControlName="LOADEMPTY"
                name="LOADEMPTY"
                id="ldStat-empty"
                type="radio"
                value="E"
              />
              <label
                for="ldStat-empty"
                class="btn btn-default"
                [ngClass]="{
                  active: bargeForm.controls.LOADEMPTY.value === 'E'
                }"
              >
                Empty
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            for="BargeTypeGroup"
          >
            Barge Type:
          </label>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div
              id="BargeTypeGroup"
              class="btn-group radio-group"
              data-toggle="buttons"
            >
              <input
                formControlName="BARGETYPE"
                name="BARGETYPE"
                id="bgType-all"
                type="radio"
                value=""
              />
              <label
                for="bgType-all"
                class="btn btn-default"
                [ngClass]="{
                  active: !bargeForm.controls.BARGETYPE.value
                }"
              >
                All
              </label>
              <input
                id="bgType-open"
                formControlName="BARGETYPE"
                name="BARGETYPE"
                type="radio"
                value="Open Hopper"
              />
              <label
                for="bgType-open"
                class="btn btn-default"
                [ngClass]="{
                  active: bargeForm.controls.BARGETYPE.value === 'Open Hopper'
                }"
              >
                Open Hopper
              </label>
              <input
                id="bgType-covered"
                formControlName="BARGETYPE"
                name="BARGETYPE"
                type="radio"
                value="Covered Hopper"
              />
              <label
                for="bgType-covered"
                class="btn btn-default"
                [ngClass]="{
                  active:
                    bargeForm.controls.BARGETYPE.value === 'Covered Hopper'
                }"
              >
                Covered Hopper
              </label>
              <input
                id="bgType-deck"
                formControlName="BARGETYPE"
                name="BARGETYPE"
                type="radio"
                value="Deck Barge"
              />
              <label
                for="bgType-deck"
                class="btn btn-default"
                [ngClass]="{
                  active: bargeForm.controls.BARGETYPE.value === 'Deck Hopper'
                }"
              >
                Deck Barge
              </label>
              <input
                id="bgType-double"
                formControlName="BARGETYPE"
                name="BARGETYPE"
                type="radio"
                value="Double Tank Barge"
              />
              <label
                for="bgType-double"
                class="btn btn-default"
                [ngClass]="{
                  active:
                    bargeForm.controls.BARGETYPE.value === 'Double Tank Barge'
                }"
              >
                Double Tank Barge
              </label>
            </div>
          </div>
        </div>

        <div class="form-group has-feedback">
          <label
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            for="Commodity"
          >
            Commodity:
          </label>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <input
              formControlName="CARGO_TYPE"
              id="Commodity"
              name="Commodity"
              type="text"
              class="form-control"
              data-bv-field="Commodity"
            />
            <i
              class="form-control-feedback"
              data-bv-icon-for="Commodity"
              style="display: none"
            ></i>
            @if (
              bargeForm.get("CARGO_TYPE")?.dirty &&
              bargeForm.get("CARGO_TYPE")?.hasError("maxLength")
            ) {
              <small
                class="help-block error-color"
                data-bv-validator="stringLength"
                data-bv-for="Commodity"
                data-bv-result="NOT_VALIDATED"
              >
                Commodity name must be less than 150 characters
              </small>
            }
          </div>
        </div>
        <div class="form-group">
          <label
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            for="CustJobId"
          >
            Customer Order Number:
          </label>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <input
              formControlName="CUST_JOB_ID"
              id="CustJobId"
              name="CustJobId"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            for="CustVesselName"
          >
            Ship Name:
          </label>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <input
              formControlName="VESSEL_NAME"
              id="CustVesselName"
              name="CustVesselName"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer modal-header-red" style="display: block">
    <div class="form-group">
      <button
        type="button"
        class="btn cancel-button pull-right"
        data-dismiss="modal"
        (click)="closeDialog()"
      >
        Close
      </button>
      <button
        id="btnCreateFilter"
        type="submit"
        class="btn border-button border-button-fill pull-right"
        [disabled]="isLoading || !bargeForm.valid"
      >
        @if (this.filter) {
          Update
        } @else {
          Create
        }
      </button>
    </div>
  </div>
</form>
