<div
  class="modal fade in"
  id="AJAXErrorModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="false"
  style="display: block; margin-top: -140px"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header modal-header-red">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
          (click)="closeDialog()"
        >
          ×
        </button>
        <h4 class="modal-title white-font" id="myModalLabel">Data Issue</h4>
      </div>
      <div class="modal-body">
        <p class="errorMsg">
          <label>--Detail--</label>
          <span>{{ message }}</span>
        </p>
      </div>
      <!-- Modal Footer -->
      <div class="modal-footer modal-header-red">
        <button
          id="btnCancelFilter"
          type="button"
          class="btn cancel-button pull-right"
          data-dismiss="modal"
          (click)="closeDialog()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
