import { Component, Inject, inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-failed-modal',
  standalone: true,
  imports: [],
  templateUrl: './failed-modal.component.html',
})
export class FailedModalComponent {
  modalRef = inject(DialogRef);
  message: string;
  constructor(@Inject(DIALOG_DATA) public data: string = '') {
    this.message = data;
  }

  closeDialog() {
    this.modalRef.close();
  }
}
